import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { debounce, isEmpty } from "lodash";
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { Account_scheme_1, Profile } from "@/interfaces/user";
import { getFilteredAccounts } from "@/utils/filter-global";
import HighlightText from "@/components/Admin/AppBar/MenuAccount/HighlightText.vue";
//@ts-ignore
import { getPermisionMenu } from "@/utils/permissionResolve";

export default Vue.extend({
	name: "MenuAccount",
	model: {},
	props: {},
	components: { IconBase, HighlightText },
	data: () => ({
		toggle: false,
		search: "",
		items: [] as Account_scheme_1[],
	}),
	created() {
		this.$nextTick(async () => {
			this.items = await this.filteredItems();
		});
	},
	beforeDestroy() {},
	async mounted() {},
	computed: {
		...mapGetters("profile", ["account", "getAbility"]),

		getProfile() {
			return this.$store.state.profile.profile;
		},
		otherAccounts() {
			const profile: Profile = this.$store.state.profile.profile;
			if (isEmpty(profile.accounts)) return [];
			// return profile.accounts.filter(
			// 	(account) => account.id != profile.account_id
			// );
			return profile.accounts.sort((a, b) => a.name.localeCompare(b.name));
		},
		highlightedText() {
			let items: Account_scheme_1[] = this.items;
			if (!this.search) return items;
			const regex = new RegExp(this.escapeRegExp(this.search), "gi");
			return items.filter(
				(item) =>
					item.name.match(regex) ||
					item.external_id.toString().match(regex)
			);
		},

		getPermission(){
			return getPermisionMenu();
		},

		canAccessCampaignMenu(){
			return this.getAbility.can(this.getPermission.actions.campaign, this.getPermission.subject);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("person", [ "clearDataToStorage" ]),
		...mapActions("report_v2", [ "resetReport" ]),
		...mapActions({
			setAccount: "user/setAccount",
		}),

		escapeRegExp(string: string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		},

		async filteredItems() {
			let accounts: Account_scheme_1[] = this.otherAccounts;
			//return await getFilteredAccounts(accounts, this.search);
			return accounts;
		},

		async dispatchChangeAccount(account_id: number) {
			this.toggle = false;
			await this.setLoadingData(TypeLoading.loading);
			await this.setAccount(account_id);
			await this.fetchProfile();
			await this.clearDataToStorage();
			this.resetReport();
			await this.redirect();
			this.setLoadingData();
			this.$emit("update");
		},
		async redirect() {
			if(this.canAccessCampaignMenu){
				if (window.location.pathname != "/admin/campaigns/index") {
					this.gotToRoute("CampaignsIndex");
				}
				return;
			}
			this.gotToRoute("Dashboard");
		},
		gotToRoute(name: string) {
			this.$router.push({ name: name });
		},
		async fetchProfile() {
			return this.$store.dispatch("profile/fetchProfile");
		},
	},
	watch: {
		toggle() {
			setTimeout(() => {
				this.search = "";
			}, 500);
		},
		search: debounce(async function () {
			this.items = await this.filteredItems();
			this.items = this.highlightedText;
		}, 500),
	},
});
