
	import Vue from "vue";
	import AppBar from "../../components/Admin/AppBar/AppBar.vue";
	// @ts-ignore
	import CustomLoading from "@/components/Commons/Loaders/Loading/index.vue";
	// @ts-ignore
	import i18n from "@/plugins/i18n";
	import { isEmpty, isNull } from "lodash";
	import { mapActions, mapGetters } from "vuex";
	// @ts-ignore
	import { TypeLoading } from "@/interfaces/loading";
	// @ts-ignore
	import { Notification, MessageTypes } from "@/interfaces/proccess";
	// @ts-ignore
	import UserAgent from "@/plugins/navigator";
	// @ts-ignore
	import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
	import { MenuRoute, prepareMenu } from "./menu";
	//@ts-ignore
	import defineAbility from "@/config/ability.ts";
	//@ts-ignore
	import { getPermisionMenu } from "@/utils/permissionResolve";
	//@ts-ignore
	import { getTokenFromStorage } from "@/services/storage-service";
	//@ts-ignore
	import { verifyTimeToken } from "@/services/jwt-service";
	// @ts-ignore
	import ConfirmRefreshToken from "@/views/Layouts/ConfirmRefreshToken.vue";
	import store from "@/store";
	import SurveyDialog from "@/components/Commons/Survey/SurveyDialog.vue";
	import { isProd } from "@/services/process-service";
import SyncPostMessageMixin from "@/mixins/SyncPostMessage";
import { MARKET_PLACE_REF } from "@/models/persons/v10/DataDefault";

	const dictionary = {
		"menu.personsMenu.personsTitle": "planning",
		"menu.personsMenu.privatePOIsTitle": "privatePois",
		"menu.personsMenu.audienceMarketplace": "planning",
		"menu.advertiser": "advertiser",
		"menu.campaign": "campaign",
		"menu.lineItem": "lineItem",
		"menu.creative": "creative",
		"menu.reporting.title": "reporting",
		"menu.reporting.direct": "directReport",
		"menu.reporting.onDemand": "reportOnDemand",
		"menu.reporting.report_all_beta": "reportAllBeta",
		"menu.reporting.pdf_reports": "reportAllBeta",
		"menu.dashboard": "dashboard",
		"menu.requestCampaign": "campaignCpi",
		"menu.tools.title": "tools",
		"menu.tools.events": "event",
		"menu.tools.list": "list",
		"menu.tools.model": "model",
		"menu.tools.modifier": "modifier",
		"menu.tools.segment": "segmento",
		"oneLink.title": "oneLink",
		"menu.tools.user": "user",
		"menu.tools.lookalike": "index_lookalike",
		"menu.tools.segment_sub": "segmento",
		"menu.tools.segment_category": "segmentoCategory",
		"menu.tools.segment_upload": "segmentoUpload",
	};

	export default Vue.extend({
		name: "LayoutAdmin",
		mixins: [SyncPostMessageMixin],
		props: {
			title: {
				type: String,
				default: "Create New Campaign",
			},
		},
		components: {
			AppBar,
			CustomLoading,
			SnackMessage,
			ConfirmRefreshToken,
			SurveyDialog,
		},
		data: () => ({
			ready: false,
			show: true,
			showTokenExpire: false,
			profile: null,
			tabs: [],
			attrs: {
				class: "mb-6",
				boilerplate: true,
				elevation: 2,
			},
			userAgent: new UserAgent(),
			accountAuthorized: false,
		}),
		created() {
			this.$nextTick(async () => {
				await this.setLoadingData(TypeLoading.loading);
				/*
								this.profile = isEmpty(this.getProfileData) 
												? await this.fetchProfile() 
												: this.getProfileData;*/

				this.profile = this.getProfileData;

				const title: string = this.getAccount?.name;
				document.title = title;

				//await this.setLoadAbility();
				//await this.setLookAndFeel()
				//await this.handlePrepareMenu();
				await this.setLoadingData();
			});
		},
		mounted() {
			this.$nextTick(async () => {
				await this.verifyToken();
			});
		},
		computed: {
			...mapGetters("profile", ["account", "getAbility", "getProfileData"]),
			...mapGetters("loading", ["isLoading"]),
			...mapGetters("internationalization", ["getSnack", "getLanguage"]),
			...mapGetters("auth", ["getToken", "isLoggedIn"]),

			getSnackMessage: {
				get() {
					return this.getSnack?.message || "";
				},
				set(value) { },
			},

			getSnackColor: {
				get() {
					return this.getSnack?.color || "";
				},
				set(value) { },
			},

			getAccount() {
				return this.account;
			},

			getItems() {
				return this.tabs;
			},

			getProfile(): any {
				return this.getProfileData;
			},
			getRoles(): Array<any> {
				const profile = this.getProfile;
				if (!profile) return [];
				return profile.roles || [];
			},
			getSuccess(): Notification {
				return {
					type: MessageTypes.SUCCESS,
					title: i18n.t("title-success"),
					message: i18n.t("account-success"),
					btn_text: i18n.t("continue"),
					to: "",
					show: true,
				} as Notification;
			},

			getPermission() {
				return getPermisionMenu();
			},
		},
		methods: {
			...mapActions("loading", ["setLoadingData"]),
			...mapActions("profile", ["setAbility", "isAccountAuthorized"]),
			...mapActions("auth", ["resetAllStorage", "signOff"]),
			...mapActions("internationalization", ["setSnack"]),

			async loadAuthorized() {
				this.accountAuthorized = await this.isAccountAuthorized(this.account.external_id);
			},

			async verifyToken() {
				setInterval(async () => {
					const hasCurrentToken = !!this.getToken && !!getTokenFromStorage();

					if (!hasCurrentToken) {
						await this.resetAllStorage();
					}

					const currentToken = this.getToken || getTokenFromStorage();
					const isShow = verifyTimeToken(currentToken);
					this.showDialogToken(isShow);
				}, 10000);
			},

			async showDialogToken(show: boolean) {
				this.showTokenExpire = show;
			},

			handleConfirmAction(action: "close" | "cancel") {

				this.showDialogToken(false);

				switch (action) {
					case "close":
						break;
					case "cancel":
						this.dispatchLogout();
						break;
				}
			},

			async filterItems(data: any) {
				const items: Array<MenuRoute> = data.filter((item: MenuRoute) => {
					item.open = false;

					if (this.isPlanningRappi(item)) return false;

					if (typeof item.children != "undefined") {
						item.children = item.children.filter((child: MenuRoute) => {
							if (typeof child.children != "undefined") {
								child.children = child.children.filter((subchild: MenuRoute) => {
									subchild.open = false;
									return this.canAccess(subchild);
								});
								child.open = false;
								return this.canAccess(child);
							} else {
								child.open = false;
								return this.canAccess(child);
							}
						});
					}
					return this.canAccess(item);
				});
				return items;
			},

			async setLoadAbility() {
				const ability = defineAbility();
				await this.setAbility(ability);
			},
			hasMenuModelPermission() {
				const permission: { [key: string]: string[] } = store.getters["profile/getPermission"];
				return Object.values(permission).some((perms: string[]) => perms.includes("menu_model"));
			},
			canAccess(item: { text: any }): Boolean {
				const model_menu = "menu.tools.model";
				const planning_menu = "menu.personsMenu.title";
				const planning_audience_blender = "menu.personsMenu.audienceMarketplace";
				const lookalike = "menu.tools.lookalike";
				const report_pdf = "menu.reporting.pdf_reports";
				const ability = this.getAbility;
				if (item.text == model_menu && this.hasMenuModelPermission()) return true;
				if (item.text == report_pdf && !this.accountAuthorized) {
					return false;
				}
				if (item.text == lookalike) {
					const action = dictionary[item.text];
					return ability.can(action, "Lookalike");
				}

				if (item.text == planning_audience_blender) {
					return ability.can(this.getPermission.actions["audienceMarketplace"], this.getPermission.subject);
				}

				if (item.text == planning_menu) {
					return (
						ability.can(this.getPermission.actions["planning"], this.getPermission.subject) ||
						ability.can(this.getPermission.actions["privatePois"], this.getPermission.subject)
					);
				} else {
					const action = dictionary[item.text];
					return ability.can(this.getPermission.actions[action], this.getPermission.subject);
				}
			},

			isPlanningRappi(item: { text: any }): Boolean {
				return item.text == "menu.personsMenu.title" && !isNull(this.getAccount.account_type) && this.isAccountRappi();
			},

			async setNotification(notification: Notification) {
				return await this.$store.dispatch("proccess/setNotification", notification, {
					root: true,
				});
			},

			async handlePrepareMenu() {
				this.tabs = await this.filterItems(prepareMenu());
			},

			async setLookAndFeel() {
				const styleSheets = Array.from(document.styleSheets).filter(styleSheet => {
					return !styleSheet.href || styleSheet.href.startsWith(window.location.origin);
				});
				for (let style of styleSheets) {
					if (style instanceof CSSStyleSheet && style.cssRules) {
						for (const rule of Object.entries(style.cssRules)) {
							if (rule[1]["selectorText"] == ":root") {
								//header_color_1
								rule[1]["style"].setProperty("--header-1-color", this.getProfile.colors.header_color_1);
								rule[1]["style"].setProperty("--v-primary-base", this.getProfile.colors.header_color_1);
								// rule[1]["style"].setProperty(
								// 	"--top-menu-1-color",
								// 	this.getProfile.colors.header_color_1
								// );
								rule[1]["style"].setProperty("--header-1-darkest-color", this.getProfile.colors.header_color_1);

								// header_color_2
								rule[1]["style"].setProperty("--header-2-color", this.getProfile.colors.header_color_2);

								rule[1]["style"].setProperty("--account_name_color", this.getProfile.colors.account_name_color);

								rule[1]["style"].setProperty("--user_name_color", this.getProfile.colors.user_name_color);

								rule[1]["style"].setProperty(
									"--background-items-accounts",
									this.getProfile.colors.header_color_background_items_accounts,
								);

								rule[1]["style"].setProperty(
									"--text-items-accounts",
									this.getProfile.colors.header_color_text_items_accounts,
								);

								// top_menu_color
								rule[1]["style"].setProperty("--top-menu-1-color", this.getProfile.colors.top_menu_color_1);
								rule[1]["style"].setProperty("--top-menu-2-color", this.getProfile.colors.top_menu_color_2);
								rule[1]["style"].setProperty("--menu_item_color", this.getProfile.colors.menu_item_color);
								rule[1]["style"].setProperty("--menu_item_hover_color", this.getProfile.colors.menu_item_hover_color);

								// SubMenu color

								rule[1]["style"].setProperty("--background-submenu", this.getProfile.colors.submenu_color_background);
								rule[1]["style"].setProperty("--text-items-submenu", this.getProfile.colors.titles_color);
								rule[1]["style"].setProperty(
									"--text-items-hover-submenu",
									this.getProfile.colors.submenu_color_hover_text_item,
								);

								//General

								// buttons_color
								rule[1]["style"].setProperty("--button-color", this.getProfile.colors.buttons_color);
								rule[1]["style"].setProperty("--v-secondary-base", this.getProfile.colors.buttons_color);

								// link_color
								rule[1]["style"].setProperty("--link-color", this.getProfile.colors.links_color);

								// icon_color
								rule[1]["style"].setProperty("--icon-color", this.getProfile.colors.icons_color);

								// title_color
								rule[1]["style"].setProperty("--title-color", this.getProfile.colors.general_color_text_titles);

								rule[1]["style"].setProperty("--link-hover-color", this.getProfile.colors.general_color_hover_text_links);
							}
						}
					}
				}
				this.ready = true;
			},

			hasSome(profileRoles: Array<any>, childRoles: Array<any>, key: string = "") {
				return profileRoles.some(rol => childRoles.indexOf(isEmpty(key) ? rol : rol[key]) > -1);
			},
			isAccountRappi() {
				const value = "rappi_amplify";
				return this.getAccount.account_type === value;
			},
			async updateData() {
				await this.setLoadingData(TypeLoading.loading);
				this.profile = this.getProfileData;
				const title: string = this.getAccount?.name;
				document.title = title;
				await this.setLoadAbility();
				await this.setLookAndFeel();
				await this.handlePrepareMenu();
				await this.setLoadingData();
			},

			async handleUpdate() {
				await this.updateData().then(r => {
					this.setNotification(this.getSuccess);
				});
			},

			async fetchProfile() {
				return this.$store.dispatch("profile/fetchProfile");
			},

			async dispatchLogout() {
				await this.setLoadingData(TypeLoading.loading);
				await this.dispatchMessage({ refName: MARKET_PLACE_REF, locale: this.getLanguage });
				await this.signOff();
				this.setLoadingData();
			},
			
		},
		watch: {
			async getProfileData(val) {
				await this.loadAuthorized();
				await this.updateData();
			},
		},
	});
