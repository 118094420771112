
	import Vue from "vue";
	// @ts-ignore
	import { mapActions, mapGetters } from "vuex";
	// @ts-ignore
	import Dialog from "@/components/Content/Dialogs/Default.vue";
	// @ts-ignore
	import CardActions from "@/components/Content/CardAction.vue";
	// @ts-ignore
	import { TypeLoading } from "@/interfaces/loading";

	import SyncPostMessageMixin from "@/mixins/SyncPostMessage";
	import { MARKET_PLACE_REF } from "@/models/persons/v10/DataDefault";

	export default Vue.extend({
		name: "ConfirmDeleteReport",
		mixins: [SyncPostMessageMixin],
		props: {
			open: {
				type: Boolean,
				default: false,
			},
		},
		components: {
			Dialog,
			CardActions,
		},
		data: () => ({}),
		created() { },
		mounted() { },
		computed: {
			...mapGetters("internationalization", ["getLanguage"]),
		},
		methods: {
			...mapActions("loading", ["setLoadingData"]),
			...mapActions("auth", ["ResetToken"]),

			handleAction(event: { type: string }) {
				const { type } = event;

				switch (type) {
					case "submit":
						this.handleActions();
						break;
					case "cancel":
						this.actionType("cancel");
						break;
				}
			},

			async actionType(action: "cancel" | "close") {
				this.$emit("action", action);
			},

			async handleActions() {
				await this.setLoadingData(TypeLoading.loading);
				await this.ResetToken()
					.then(async () => {
						await this.setLoadingData();
						this.dispatchMessageToMarketPlace();
					})
					.catch(async () => { })
					.finally(() => {
						this.setLoadingData();
						this.actionType("close");
					});
			},

			dispatchMessageToMarketPlace() {
				this.dispatchMessage({ refName: MARKET_PLACE_REF, locale: this.getLanguage });
			},
		},
		watch: {},
	});
