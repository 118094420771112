import { AVATAR_DEFAULT } from "@/services/profile-service";
import Vue from "vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import PhotographicView from "@/components/Admin/AppBar/Photographic/Photographic.vue";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import UserSetting, {
	UserRulesEntity,
	UserSettingBody,
	UserSettingField,
} from "@/models/Setting";
// @ts-ignore
import { Profile, UserRules } from "@/interfaces/user";

export default Vue.extend({
	name: "UserName",
	model: {},
	props: {},
	components: {
		Dialog,
		PhotographicView
	},
	data: () => ({
		modal:{
			open: false,
			title: i18n.t("menu_img.view_img"),
			edit: false,
			height: 250,
			width: 600,
			loading: false
		},
		userSetting: new UserSetting(),
		items: [
			{ icon: true, title: 'Jason Oner', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg' },
		],
	}),
	created() {
		this.$nextTick(() => {
			this.prepareUserSettingData();
		});
	},
	beforeDestroy() {},
	async mounted() {},
	computed: {
		...mapGetters("profile", {
			getUserSetting: "profile",
		}),
		getAvatar() {
			return this.getUserSetting?.avatar_file ?? ""
		},
		getAvatarDefault() {
			return AVATAR_DEFAULT
		},
		getOptions(){
			return [
				{id: 1, value: i18n.t("menu_img.view_img")},
				{id: 2, value: i18n.t("menu_img.update_img")}
			]
		},
		getTitleModal(){
			return this.modal.title;
		},
		getUserSettingParams() {
			let userSettingBody: UserSettingBody = {
				name: this.userSetting.name,
				last_name: this.userSetting.last_name,
				role_id: this.userSetting.role_id,
				language: this.userSetting.language,
				avatar_file: this.userSetting.upload_file,
			};

			return {
				id: this.userSetting.id,
				user: userSettingBody,
			};
		},
	},
	methods: {
		...mapActions("profile", ["setProfileData"]),
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("user_setting", {
			updateUserSetting: "update",
		}),

		handleCloseModal(){
			this.modal.open = false;
			this.modal.edit = false;
			this.modal.title = i18n.t("menu_img.view_img");
			this.modal.height = 250,
			this.modal.width = 600
		},

		prepareUserSettingData() {
			const profile: Profile = this.getUserSetting;
			let userSetting = new UserSetting(profile);
			this.userSetting = userSetting;
		},

		handleChangeImage(){
			this.handleChangeImg(2);
		},

		handleUpdateUpload(event: {
			avatar_file: string | null;
			upload_file: File | null;
		}) {
			const { avatar_file, upload_file } = event;

			// upload_file
			this.userSetting.setData(UserSettingField.UPLOAD_FILE, upload_file);

			// avatar_file
			this.userSetting.setData(UserSettingField.AVATAR_FILE, avatar_file);
		},

		async handleSave(){
            try {

				this.modal.loading = true;

				await this.updateUserSetting(this.getUserSettingParams);
				await this.setProfileData({
					key: "avatar_file",
					value: this.userSetting.avatar_file,
				});

				this.modal.loading = false;

				this.handleCloseModal();
			} catch (error) {
				this.modal.loading = false;
			}
        },

		async handleChangeImg(id: number, open: boolean = true){
			this.modal.open = open;
			this.modal.title = id == 1 ? i18n.t("menu_img.image") : i18n.t("menu_img.update_img");
			this.modal.edit = id == 2;
			this.modal.height = id == 1 ? 250 : 450;
			this.modal.width = id == 1 ? 600 : 900;
		}
	},
	watch: {},
});
