var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-toolbar',{attrs:{"color":"transparent","flat":"","tile":"","dark":""}},[_c('v-toolbar-items',{attrs:{"color":"transparent"}},[_vm._l((_vm.items),function(item){return [(!item.children)?[_c('v-btn',{key:item.text,staticClass:"menu-item-color",class:{
						'v-btn--active menu-item-active v-list-item--active':
							_vm.isActive(item.href),
					},attrs:{"to":item.href,"data-cy":item.dataCy,"text":""}},[(item.icon)?_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")],1)]:[_c('v-menu',{key:item.text,attrs:{"color":"menu-item-hover-color","offset-y":"","transition":"slide-y-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-item-color",class:{
								'v-btn--active menu-item-active v-list-item--active':
									_vm.hasChildrenCurrentPath(item.children),
							},attrs:{"dark":"","text":"","disabled":item.disabled,"data-cy":item.dataCy}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(item.text))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',{staticClass:"v-tools color-background-submenu p-0",attrs:{"color":"color-background-submenu","tile":""}},_vm._l((item.children),function(children){return _c('v-list-item',{key:children.text,staticClass:"color-text-items-submenu",class:{
								'v-list-item--active': _vm.isActive(
									children.href
								),
							},attrs:{"to":children.href,"disabled":children.disabled,"data-cy":children.dataCy,"router":"","dark":""}},[(children.children)?[_c('v-menu',{key:children.text,staticClass:"v-tools p-0",attrs:{"color":"color-background-submenu","offset-x":"","transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({key:children.text,staticClass:"color-text-items-submenu",attrs:{"disabled":children.disabled}},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(children.text))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-right ")])],1)]}}],null,true),model:{value:(children.open),callback:function ($$v) {_vm.$set(children, "open", $$v)},expression:"children.open"}},[_c('v-list',{staticClass:"v-tools p-0",attrs:{"tile":""}},_vm._l((children.children),function(subchildren){return _c('v-list-item',{key:subchildren.text,staticClass:"color-text-items-submenu",class:{
												'v-list-item--active':
													_vm.isActive(
														subchildren.href
													),
											},attrs:{"to":subchildren.href,"disabled":subchildren.disabled,"router":"","dark":"","data-cy":subchildren.dataCy},on:{"click":function($event){return _vm.setMenu()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(subchildren.text))+" ")])],1)}),1)],1)]:[(children.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" "+_vm._s(children.icon)+" ")])],1):_vm._e(),_c('v-list-item-action',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(children.text))+" ")])],1)]],2)}),1)],1)]]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }